import React from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../../components/layoutNoStripe"
import {
  Section,
} from "../../styledComponents/section"
import permissionToSleep from "../../fixtures/permission-stress-depression-anxiety"

import {
  StyledAudio,
} from "../../styledComponents/app/audioCard"
import AudioCard from "../../components/App/audioCard"

const PermissionToResetStressDepressionAnxiety = ({ data, location }) => {
  const identity = useIdentityContext()
  const sleep = permissionToSleep
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <LayoutNoStripe location={location} isHomePage mode="night">
        <Section className='singleAudio'>
        <h1>Permissioning™ - Stress, Depression &amp; Anxiety</h1>
          <StyledAudio type="singleAudio">
          {
            sleep[0].audioFiles.map(audio => {
              let key = audio.title.toLowerCase().replace(/\s/g, '')
              return (
                <>
                <AudioCard 
                  key={key}
                  title={audio.title}
                  image={audio.image}
                  content={audio.content}
                  audioMp3={audio.audioMp3}
                  duration={audio.duration} 
                  v="singleAudio"
                />  
                </>   
              )
            })
          }
          </StyledAudio>
        </Section>
    </LayoutNoStripe>
    </>
  )
}

export default PermissionToResetStressDepressionAnxiety
