const fixture = [
    {
      programName: "Permission Free Stree, Depression & Anxiety",
      featuredImage: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
      audioFiles: [
       
        {
            title: "Stress, Depression & Anxiety",
            image: "/app/images/permission-bonus/stress.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/stress-depression-anxiety-12022020.m4a"
        },
      ],
    }
  ]
  
  export default fixture
  




// sound-bell.jpg