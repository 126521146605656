import React from "react"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import AudioModal from "./AudioModal"
import {
    StyledAudioCard,
} from "../../styledComponents/app/audioCard"
  
const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
`

const AudioCard = props => {
    return (        
        <StyledAudioCard color={props.color} v={props.v}>
            <ModalProvider backgroundComponent={FadingBackground}>
                <AudioModal audio={props} />
            </ModalProvider>
        </StyledAudioCard>
    )
}

export default AudioCard
